<template>
  <app-side-menu :min-width="15"
    :fixed-menu="true">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Inv No: {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.QuickInvoiceDetail.name, params: { invoiceId : $route.params.invoiceId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
              :data-badge="$v.detailGroup.$error ? '' : null">
              Detail Info
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.QuickInvoiceItems.name, params: { invoiceId : $route.params.invoiceId }, query: $route.query }"
            active-class="is-active"
            exact>
            <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.entity.items.$error }"
              :data-badge="$v.entity.items.$error ? '' : null">
              Invoice Items
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import QuickInvoiceRoutes from './route-types'

export default {
  name: 'QuickinvoiceSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    entityName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return QuickInvoiceRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>
